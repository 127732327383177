import { NavLink, routes } from '@redwoodjs/router'

const UnauthenticatedLayout = ({
  showLogin = true,
  whiteLabel = 'Briefcase',
}: {
  showLogin?: boolean
  whiteLabel?: string
}) => {
  return (
    <>
      <header className="px-4 lg:px-6 py-2 flex items-center dark:text-white">
        <span className="text-3xl font-bricolage-semibold">{whiteLabel}</span>
        {showLogin && (
          <nav className="ml-auto flex gap-4 sm:gap-6">
            <NavLink
              to={routes.login()}
              className="text-sm font-medium hover:underline underline-offset-4 cursor-pointer dark:text-primary-500 dark:hover:underline"
              activeClassName=""
            >
              Log in
            </NavLink>
          </nav>
        )}
      </header>
      <hr className="border-gray-300 dark:border-gray-600" />
    </>
  )
}

export default UnauthenticatedLayout
