import { ErrorMessage } from '@hookform/error-message'
import { useForm } from '@redwoodjs/forms'
import { Metadata } from '@redwoodjs/web'
import { Button, Label, TextInput } from 'flowbite-react'
import { graphql, useMutation } from 'react-relay'
import { toast } from 'react-toastify'

import browserLogger from 'src/browserLogger'
import { Constants } from 'src/constants'
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout'

import { RequestPasswordResetPageMutation } from './__generated__/RequestPasswordResetPageMutation.graphql'

const REQUEST_PASSWORD_RESET = graphql`
  mutation RequestPasswordResetPageMutation(
    $input: RequestPasswordResetInput!
  ) {
    requestPasswordReset(input: $input) {
      success
    }
  }
`

const RequestPasswordResetPage = () => {
  const [requestPasswordReset, isRequestPending] =
    useMutation<RequestPasswordResetPageMutation>(REQUEST_PASSWORD_RESET)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      requestPasswordReset({ variables: { input: { email: data.email } } })
      toast.success(
        'Check your email for a reset password email, and follow the link to enter a new password',
        {
          autoClose: Constants.DEFAULT_TOAST_CLOSE,
        }
      )

      browserLogger.info('Password reset requested', {
        email: data.email,
      })
    } catch (error) {
      toast.error(
        'Failed to request a password reset, please try again later',
        {
          autoClose: Constants.DEFAULT_TOAST_CLOSE,
        }
      )
      browserLogger.error('Failed to request new password', {
        error,
        email: data.email,
      })
    }
  }

  return (
    <div className="dark:bg-gray-800 h-screen">
      <Metadata title="Reset Password" description="Reset Password page" />
      <UnauthenticatedLayout />

      <main className="flex justify-center mx-4 items-center mt-32">
        <div className="w-full max-w-md bg-white border border-gray-200 rounded-lg shadow p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <form
            className="flex flex-col gap-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-2xl font-bold dark:text-white">
              Reset Password
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Enter your email address and we&apos;ll send you a link to reset
              your password
            </p>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                id="email"
                type="email"
                placeholder="example@example.com"
                {...register('email', {
                  required: 'Email is required',
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="text-red-500 dark:text-red-400 text-sm mt-1">
                    {message}
                  </p>
                )}
              />
            </div>

            <Button
              type="submit"
              disabled={isRequestPending}
              className="bg-primary-700"
            >
              {isRequestPending ? 'Sending...' : 'Send reset link'}
            </Button>
          </form>
        </div>
      </main>
    </div>
  )
}

export default RequestPasswordResetPage
