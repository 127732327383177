import React, { useCallback, useContext, useMemo, useState } from 'react'

import { AvailableRoutes, Redirect, routes } from '@redwoodjs/router'
import {
  FirebaseError,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from 'firebase/app'
import * as firebaseAuth from 'firebase/auth'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { authVerifiedEmailQuery } from './__generated__/authVerifiedEmailQuery.graphql'
import browserLogger from './browserLogger'

export function filterNulls<T>(input: Array<T | null | undefined>): T[] {
  return input.filter(Boolean) as T[]
}

export function first<T>(array: ReadonlyArray<T>): T | undefined {
  return array[0]
}

export default function useCounter(initialValue = 0): {
  value: number
  increment: () => void
  decrement: () => void
  setValue: (value: number) => void
} {
  const [value, setValue] = useState(initialValue)
  const increment = useCallback(() => setValue((v) => v + 1), [])
  const decrement = useCallback(() => setValue((v) => v - 1), [])
  return {
    value,
    increment,
    decrement,
    setValue,
  }
}

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
}

const app = ((config) => {
  const apps = getApps()

  if (!apps.length) {
    initializeApp(config)
  }

  const app = getApp()

  return app
})(firebaseConfig)

type AuthState =
  | { status: 'INITIALIZING'; promise: Promise<unknown> }
  | {
      status: 'READY'
      currentUser: firebaseAuth.User | null
    }
  | { status: 'ERROR'; error: Error }

type AuthContextType = {
  rerender: () => void
}

const AuthContext = React.createContext<AuthContextType | null>(null)

const firebaseInitializedPromise = new Promise<firebaseAuth.User | null>(
  (resolve, reject) => {
    const unsub = firebaseAuth.getAuth(app).onAuthStateChanged(
      (user) => {
        unsub()
        resolve(user)
      },
      (err) => {
        unsub()
        reject(err)
      }
    )
  }
)

let state: AuthState = {
  status: 'INITIALIZING',
  promise: firebaseInitializedPromise.then(
    (user) => {
      browserLogger.debug('Ready for auth', { user })
      state = {
        status: 'READY',
        currentUser: user,
      }
    },
    (error) => {
      browserLogger.debug('Auth error', { error })
      state = {
        status: 'ERROR',
        error,
      }
    }
  ),
}
firebaseAuth.getAuth(app).onIdTokenChanged(async (user) => {
  state = { status: 'READY', currentUser: user }
})

export function AuthProvider(props: { children: React.ReactNode }) {
  const { value: tick, increment: rerender } = useCounter()
  const value = useMemo(
    () => ({
      rerender: () => {
        browserLogger.debug('rerendering auth context', { state, tick })
        rerender()
      },
      tick,
    }),
    [tick, rerender]
  )
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}

function useAuthContext(): AuthContextType {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return state
}

export async function getAccessToken(): Promise<{
  token: string
  provider: string
} | null> {
  if (state.status === 'INITIALIZING') {
    await state.promise
  }
  if (state.status !== 'READY') {
    return null
  }
  const { currentUser } = state
  if (!currentUser) {
    return null
  }
  const token = await currentUser.getIdToken()
  return {
    token,
    provider: 'firebase',
  }
}
export type User = {
  id: string
  email?: string
  displayName?: string
  isAnonymous: boolean
}

function firebaseUserToUser(user: firebaseAuth.User): User {
  const displayNameFromOtherProviders = first(
    filterNulls(user.providerData.map((u) => u.displayName))
  )
  return {
    id: user.uid,
    email: user.email ?? undefined,
    displayName: user.displayName ?? displayNameFromOtherProviders,
    isAnonymous: user.isAnonymous,
  }
}

export function useUser(): User | null {
  useContext(AuthContext)
  if (state.status === 'ERROR') {
    throw state.error
  }
  if (state.status === 'INITIALIZING') {
    throw state.promise
  }
  const user = state.currentUser
  return useMemo(() => (user ? firebaseUserToUser(user) : null), [user])
}

export enum SSOProvider {
  Google = 'google',
  Facebook = 'facebook',
  Microsoft = 'microsoft',
}

type AuthActions = {
  deleteUser: () => Promise<void>
  signUp: (args: { email: string; password: string }) => Promise<User>
  logOut: () => Promise<void>
  resetPassword: (args: { newPassword: string; code: string }) => Promise<void>
  signInWithEmailAndPassword: (args: {
    email: string
    password: string
  }) => Promise<User>
}

export function useAuthActions(): AuthActions & {
  authTransaction: <TResult>(
    fn: (actions: AuthActions) => Promise<TResult>
  ) => Promise<TResult>
} {
  const state = useAuthContext()
  return useMemo(() => {
    const stateRerender = state.rerender
    const actions = (rerender: () => void): AuthActions => ({
      deleteUser: async () => {
        const user = firebaseAuth.getAuth(app).currentUser
        if (!user) {
          browserLogger.error('user not found when deleting user')

          throw new Error('user not found')
        }
        await user.delete()
        rerender()
      },
      signInWithEmailAndPassword: async ({ email, password }) => {
        const user = await firebaseAuth.signInWithEmailAndPassword(
          firebaseAuth.getAuth(app),
          email,
          password
        )
        rerender()
        return firebaseUserToUser(user.user)
      },
      signUp: async ({ email, password }) => {
        const user = await firebaseAuth.createUserWithEmailAndPassword(
          firebaseAuth.getAuth(app),
          email,
          password
        )
        rerender()
        return firebaseUserToUser(user.user)
      },
      logOut: async () => {
        browserLogger.debug('Logging out')
        await firebaseAuth.signOut(firebaseAuth.getAuth(app))
        rerender()
      },
      resetPassword: async ({ newPassword, code }) => {
        await firebaseAuth.confirmPasswordReset(
          firebaseAuth.getAuth(app),
          code,
          newPassword
        )
        rerender()
      },
    })
    return {
      ...actions(stateRerender),
      authTransaction: async (fn) => {
        const result = await fn(actions(() => {}))
        stateRerender()
        return result
      },
    }
  }, [state.rerender])
}

export function Authenticated({
  children,
  unauthenticatedRoute,
}: {
  children: React.ReactNode
  unauthenticatedRoute: keyof AvailableRoutes
}) {
  const isAuthenticated = useUser() != null
  browserLogger.info('Checking if user is authenticated', { isAuthenticated })

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Redirect to={routes[unauthenticatedRoute]()} />
  )
}

export function EmailVerified({
  children,
  unauthenticatedRoute,
}: {
  children: React.ReactNode
  unauthenticatedRoute: keyof AvailableRoutes
}) {
  const data = useLazyLoadQuery<authVerifiedEmailQuery>(
    graphql`
      query authVerifiedEmailQuery {
        user {
          emailVerified
        }
      }
    `,
    {}
  )

  if (!data) {
    return <Redirect to={routes[unauthenticatedRoute]()} />
  }
  if (data.user && !data.user.emailVerified) {
    return <Redirect to={routes.emailNotVerified()} />
  }
  return <>{children}</>
}

export function Unauthenticated({
  children,
  authenticatedRoute,
}: {
  children: React.ReactNode
  authenticatedRoute: keyof AvailableRoutes
}) {
  const isAuthenticated = useUser() != null
  return isAuthenticated ? (
    <Redirect to={routes[authenticatedRoute]()} />
  ) : (
    <>{children}</>
  )
}

export function isFirebaseError(error: unknown): error is FirebaseError {
  return error instanceof Error && error.name === 'FirebaseError'
}

export const PasswordValidation = {
  required: 'Password is required',
  minLength: {
    value: 10,
    message: 'Password must be at least 10 characters',
  },
}
